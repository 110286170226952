.qiMap {
  height: calc(100vh - 50px);
}

.collapsable-side-panel {
  position: absolute;
  z-index: 100;
}

.qi-map-view-left-panel_list {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.qi-map-view-left-panel_list {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

/*
.main-container_header_link {
  align-items: center;
  justify-content: start;
}
*/

.qi-expanded {
  &.map-view-flyout {
    width: 420px;

    .track-points-datepicker-wrapper {
      @apply m-3;
    }

    .qi-pagination-wrapper {
      @apply px-2;
    }

    .qi-pagination_item_link {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  &_header {
    align-items: center;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
    padding-bottom: 5px;

    .search-form {
      width: 260px;
    }
  }

  .qi-list-view_list {
    height: calc(100vh - 185px);
    margin-top: 15px;

    .device-and-status {
      position: relative;
      width: 60px;

      .ignition {
        height: 20px;
        right: 14px;
        position: absolute;
        top: 12px;
        width: 20px;

        .icon.engine {
          height: 20px;
          width: 20px;
        }
      }
    }

    .map-view-flyout_item-info {
      width: calc(100% - 60px);
    }

    &_item {
      align-items: flex-start;
    }
  }
}

.tracking-app-container-wrapper {
  @apply flex;
}

.tracking-app-main-container-wrapper {
  @apply grow;

  height: calc(100vh - 58px);
  overflow: hidden;
}

.tracking-app-main-container {
  @apply flex;

  height: 100%;

  &_block {
    @apply w-full;

    &_header {
      @apply flex justify-between items-center pr-2;
    }
  }

  &_block {
    &_header {
      &_secondary {
        @apply flex items-center bg-white;

        justify-content: end;
        margin-bottom: 0.5em;

        .switch {
          height: 24px;
          width: calc(27px * 1.75);

          .slider::before {
            height: calc(23px - 8px);
            width: calc(23px - 8px);
          }
        }

        .config-button {
          @apply flex items-center;

          &:hover {
            cursor: pointer;

            .icon {
              fill: #0767d0;
            }
          }
        }
      }
    }

    .no-data {
      height: calc(100vh - 144px);
    }

    &.Activities {
      .no-data {
        height: calc(100vh - 126px);
      }
    }

    &.TrackPoints {
      .map-and-details {
        position: relative;

        .qi-list-view-column-config {
          position: absolute;
          background: #fff;
          right: 18px;
          top: 40px;
          width: 575px;
          z-index: 1;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          padding: 15px;
          border-radius: 3px;

          &::after {
            border-color: transparent transparent #f5f6fa;
            border-style: solid;
            border-width: 0 6px 10px;
            content: " ";
            display: block;
            height: 0;
            position: absolute;
            right: 56px;
            top: -6px;
            width: 0;
          }

          .qi-multiselect-dropdown {
            margin-bottom: 0;
          }
        }
      }

      .qi-list-view_list {
        height: calc(100vh - 252px);
      }
    }
  }
}

.raw-data-section {
  display: flex;

  .qi-input {
    align-items: center;
    border: none;
    height: auto;
    margin: 0 1em 0 0;
    padding: 0;
    width: auto;

    &:hover {
      background-color: transparent;
    }

    .qi-input_label {
      font-size: 14px;
      width: auto;
    }

    .qi-input_wrapper {
      width: auto;
    }
  }
}

.map-view-tabs {
  @apply flex justify-between;
}

.map-and-details {
  width: 100%;

  .ol-popup {
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 15px 20px 10px;

    &-closer {
      right: 5px;
      top: 5px;
    }
  }

  &_tabs {
    @apply flex py-3;

    font-weight: 600;
    text-transform: uppercase;

    &_item {
      @apply mx-3;

      position: relative;
      border-radius: 4px;
      cursor: pointer;
      padding: 5px 15px;
      transition: all 0.35s ease;
      white-space: nowrap;

      & > .live-icon {
        position: absolute;
        width: 10px;
        height: 10px;
        right: -2px;
        top: 32%;
        border-radius: 50%;
        animation: beep 0.8s infinite;

        @keyframes beep {
          from {
            background: rgb(248, 207, 207);
          }

          to {
            background-color: red;
          }
        }
      }

      &.active,
      &:hover {
        background-color: #0767d0;
        color: #fff;

        & > .live-icon {
          right: -15px;
        }
      }
    }

    .track-points-datepicker-wrapper {
      border-radius: 3px;
      padding: 1px 5px;
    }
  }

  .details-block {
    &_summary {
      &_list {
        &_item {
          padding: 3px 0;
        }
      }
    }
  }

  &.split-right,
  &.split-left {
    @apply flex;

    .details-block_header {
    }

    .details-block {
      height: auto;
      width: 500px;
    }

    .map-container {
      width: calc(100% - 500px);
    }

    .details-block {
      &_summary {
        @apply mb-3;

        width: auto;

        &_item-info {
          @apply flex;
        }

        .item-photo {
          @apply mr-3;
        }
      }
    }

    .details-block_section {
      flex-direction: column;
      height: calc(100vh - 144px);
      overflow: auto;
    }

    .details-block_data-container-wrapper {
      height: calc(100vh - 162px);
      width: auto;
    }

    .details-block_data.details-block_other-data {
    }

    .details-block_other-data_item {
      width: 100%;
    }

    &.map-container {
      .card-view-list.events-list {
        height: calc(100% - 50px);
      }
    }

    .qi-list-view_header {
      display: none;
    }

    .qi-list-view_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      & > li {
        border: 1px solid #eee;
        border-radius: 4px;
        margin: 0.5em 0;
        width: 100%;

        .qi-list-view_list_item {
          align-items: start;
          flex-direction: column;

          .qi-list-view_column {
            border-bottom: 1px solid #e9e9e9;
            display: flex;
            width: 100% !important;

            &.status {
              text-align: center;
            }

            &.action {
              justify-content: flex-start;
              padding-left: 1em;
            }

            .associated-devices .qi-dropdown_menu,
            .more .qi-dropdown_menu {
              left: auto;
              right: 0;
              top: 22px;
            }

            .more-options-group .qi-dropdown_menu {
              flex-direction: revert;
              left: 2em;
              right: auto;
              top: -10px;
            }
          }
        }

        .qi-list-view_column_key-label {
          display: inline-block;
          margin-right: 0.5em;
          width: 40%;
        }
      }
    }
  }

  .details-block {
    &_device-imei {
      /* Set the maximum width for the text container */
      max-width: 300px;
      /* Adjust this value as needed */

      /* Allow text to wrap within the container */
      white-space: normal;

      /* Add ellipsis (...) to indicate text overflow */
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.split-left {
    @apply flex-row-reverse;
  }

  &.split-down {
    .map-container,
    .qiMap,
    .vehicle-track,
    .trips-map {
      height: calc(100vh - 460px);
      //transition: all 0.35s ease;
    }

    .qi-list-view_list {
      height: 220px;
    }

    .details-block_section .trips .qi-list-view_list {
      height: 214px;
    }

    .no-data {
      height: 268px;
    }

    .vehicle-trips {
      margin: 0;
    }

    .details-block_section {
      height: calc(100% - 100px);
    }

    .device-chip {
      height: 10em;
      padding: 1em;
      overflow: auto;
    }

    &.minimized-details {
      .map-container,
      .qiMap {
        height: calc(100vh - 145px);
      }

      .vehicle-track {
        height: calc(100vh - 200px);
      }

      .trips-map {
        height: calc(100vh - 100px);
      }

      .details-block_section {
        display: none;
      }

      .details-block {
        position: relative;
        z-index: 9999;
      }
    }
  }

  .map-container,
  .qiMap {
    height: calc(100vh - 58px);
    width: 100%;
  }
}

.tracking-app-main-container_block_header
  + .map-and-details.split-down.minimized-details
  .map-container.qiMap-split {
  height: calc(100vh - 145px);
}

.tracking-app-main-container_block_header
  + .tracking-app-main-container_block_header_secondary
  + .map-and-details.split-down.minimized-details
  .map-container.qiMap-split {
  height: calc(100vh - 185px);
}

.selected {
  @apply bg-sky-100;
}

.card-view-list {
  &.vehicles-list {
    height: calc(100vh - 198px);
    margin-top: 15px;
    overflow: auto;

    .status {
      &_block {
        display: flex;
        justify-content: center;
        position: relative;
      }

      .active,
      .offline,
      .idle {
        border: 2px solid #fff;
        border-radius: 50%;
        display: inline-block;
        height: 16px;
        width: 16px;

        &_mobile {
          right: 10px;
          top: -3px;
        }
      }

      .active {
        background-color: #759d26;
      }

      .idle {
        background-color: #f0cf20;
      }

      .offline {
        background-color: #999;
      }
    }

    .vehicles-time-stamp {
    }

    .status {
      .icon.device {
        height: 1.75em;
        width: 1.75em;
      }

      .icon.engine {
        background-color: #fff;
        border-radius: 50%;
        height: 20px;
        left: 18px;
        position: absolute;
        top: 12px;
        width: 20px;
      }
    }

    .date-and-time {
      text-align: right;
      width: 202px;

      .vehicles-time-stamp {
        @apply flex;

        .status-and-connected {
          margin-right: 5px;
          padding-top: 3px;
        }
      }
    }
  }

  &.trips-list {
    height: calc(100vh - 215px);
    margin-top: 15px;
    overflow: auto;
    .card-view-list_item {
      padding: 0.5em 1em 0.5em 0;
    }

    .status {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      width: 40px;

      .status-completed,
      .status-in-progress,
      .status-parked {
        border-radius: 50%;
        display: inline-block;
        height: 30px;
        padding: 5px;
        width: 30px;
      }

      .status-completed {
        background: #e0fdd4;
      }

      .status-in-progress {
        background: #fdf7d4;
      }

      .status-parked {
        background: #d0f4ff;

        .icon {
          fill: #5198e6;
        }
      }
    }

    .item-id {
      font-weight: 700;
      margin: 0 0 5px 10px;
    }

    .trip-and-time {
      @apply flex justify-between;

      background-color: #f7f7f7;
      border: 1px solid #eee;
      border-radius: 2px;
      padding: 3px 10px;
    }

    .trip-start-time,
    .trip-end-time {
      position: relative;

      &::before {
        content: " ";
        display: inline-block;
        height: 16px;
        position: absolute;
        left: 30px;
        top: 4px;
        width: 16px;
      }
    }

    .trip-start-time {
      margin-bottom: 12px;

      &::before {
        border: 4px solid #0767d0;
        border-radius: 50%;
      }
    }

    .trip-end-time {
      &::before {
        border: 4px solid #759d26;
        border-radius: 50% 50% 50% 0;
        transform: rotate(-45deg);
      }
    }

    .date-and-time {
      text-align: right;
    }
  }

  &.events-list {
    height: calc(100vh - 215px);
    margin-top: 15px;
    overflow: auto;

    .item-id {
      font-weight: 700;
      margin: 0 0 5px 10px;
    }

    .events-and-status {
      @apply flex justify-between;

      background: #f7f7f7;
      border-radius: 2px;
      padding: 3px 10px;
      width: 200px;
    }

    .date-and-time {
      text-align: right;
    }

    .card-view-list_item {
      padding: 0.5em 1em 0.5em 0;
    }
  }
}

.asset-list-wrapper {
  .asset-list_header {
  }

  .asset-list {
    margin-top: 0;
  }

  .ignition-status,
  .vehicle-id,
  .device-id,
  .speed,
  .status,
  .last-received {
    padding: 0.25em;
  }

  .ignition-status {
    position: relative;
    width: 60px;

    .ignition {
      left: 18px;
      position: absolute;
      top: 10px;

      .icon.engine {
        height: 16px;
        width: 16px;
      }
    }
  }

  .vehicle-id,
  .device-id {
    width: 130px;
  }

  .speed {
    width: 75px;
  }

  .status {
    position: relative;
    text-align: center;
    width: 55px;

    .active,
    .active,
    .offline,
    .idle {
      border: 2px solid #fff;
      border-radius: 50%;
      display: inline-block;
      height: 16px;
      width: 16px;
    }

    .active {
      background-color: #759d26;
    }

    .idle {
      background-color: #f0cf20;
    }

    .offline {
      background-color: #999;
    }

    .latest-data-time {
      background-color: #e8faff;
      border: 1px solid #d7d7d7;
      border-radius: 10px;
      box-shadow: 3px 3px 5px #bbb;
      display: none;
      left: -34px;
      top: 5px;
      font-size: 11px;
      padding: 2px 10px;
      position: absolute;
      width: max-content;
    }

    &:hover {
      .latest-data-time {
        display: inline-block;
      }
    }
  }

  .last-received {
    width: 100px;
  }

  .card-view-list {
    &_item {
      @apply flex justify-between;

      align-items: center;
      border-bottom: 1px solid #eee;
      transition: 0.3s;

      &:hover {
        @apply bg-sky-100;
      }

      .trip-and-time {
        @apply flex justify-between;
      }

      .card-view-list_item_info {
        padding-right: 1em;
        width: calc(100% - 242px);
      }

      .date-and-time {
        width: 202px;
      }
    }
  }
}

.track-points,
.trips,
.vehicle-trips,
.vehicle-events {
  @apply m-3 bg-white;
}

.track-points {
  position: relative;

  .qi-list-view-column-config {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    top: -32px;
    width: 500px;
    z-index: 1;

    .qi-multiselect-dropdown {
      @apply m-3;

      flex-direction: column;
    }

    .qi-multiselect-dropdown_wrapper {
      @apply mt-3;

      width: auto;
    }
  }
}

.trips {
  .trips-list-wrapper {
    @apply flex justify-between px-2;

    flex-wrap: wrap;
    max-height: calc(100vh - 175px);
    overflow: auto;
  }
}

.download-status {
  display: inline-block;
}

.truncate {
  /* Set the maximum width for the text container */
  max-width: 300px;
  /* Adjust this value as needed */

  /* Allow text to wrap within the container */
  white-space: normal;

  /* Add ellipsis (...) to indicate text overflow */
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-container {
  .ol-attribution {
    display: none;
  }
}

.raw-data {
  margin: 10px;
  background: #fff;

  .code-format {
    position: relative;
    height: 70px;

    .clip {
      position: absolute;
      margin-bottom: 10px;
      border: 1px solid #31c786;
      background: #31c786;
      color: #fff;
      left: 0;
      top: 0;
      font-size: 12px;
      z-index: 99;
      cursor: grabbing;

      &:hover {
        background: #01f189;
        color: #fff;
      }
    }

    .content {
      margin-top: 10px;
    }
  }
}
