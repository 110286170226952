.item-list-right-layout {
  flex-direction: column;

  &.split-down {
    .tracking-app-main-container_block {
      height: calc(100vh - 400px);
    }

    .card-view-list.vehicles-list {
      height: 100%;
    }

    .qi-expanded_body {
      height: calc(100% - 6rem);
    }

    .map-and-details .map-container,
    .map-and-details .qiMap {
      height: 400px;
    }
  }

  .tracking-app-main-container_block.Map,
  .tracking-app-main-container_block.TrackPoints,
  .tracking-app-main-container_block.Activities,
  .tracking-app-main-container_block.Trips,
  .tracking-app-main-container_block.Events,
  .tracking-app-main-container_block.RawData,
  .tracking-app-main-container_block.LiveTrack {
    display: flex;
    flex-flow: row-reverse;

    .map-and-tabs-container {
      width: calc(100% - 20px);
    }

    .qi-expanded {
      &.map-view-flyout {
        & + .map-and-tabs-container {
          width: calc(100% - 420px);
        }
      }
    }
  }

  .details-block {
    position: relative;
  }
}
