.qi-header {
  @apply flex items-center bg-white w-full px-4 py-2 relative;

  margin-bottom: 1px;
  z-index: 999;

  .branding {
    align-items: center;
    display: flex;
    height: 40px;
    max-width: 200px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &_title {
    @apply grow text-base font-bold;

    &_text {
      @extend .text-link;
    }
  }

  .user-profile-name {
    @apply flex items-center;

    .user-profile-image-container {
      @apply flex items-center rounded-full mr-2 bg-sky-100 justify-center;

      height: 40px;
      overflow: hidden;
      width: 40px;
    }

    .user-profile-image {
      max-height: 100%;
    }
  }

  .user-dropdown {
    &_user-info {
      @apply text-left p-4 border-b;
    }

    &_link {
      @apply flex items-center cursor-pointer p-2 border-b;

      &.read {
        background-color: white;
      }

      &.unread {
        @apply bg-orange-100;
      }

      .icon-wrapper {
        @apply p-2;
      }

      &:hover,
      &.active {
        @apply bg-sky-50;

        .icon {
          fill: #5c7989;
        }
      }

      &.active {
        @apply font-semibold cursor-auto;
      }

      &_item {
        @apply flex items-center;
      }

      .application-icon-wrapper + span {
        text-align: left;
        width: calc(100% - 36px);
      }
    }
  }

  .qi-dropdown {
    &.user-action-dropdown {
      @apply relative;

      .qi-dropdown_menu {
        top: 42px;

        &::before,
        &::after {
          @apply absolute;

          border-width: 0 10px 10px;
          border-style: solid;
          content: " ";
          display: inline-block;
          height: 0;
          width: 0;
        }

        &::before {
          border-color: transparent transparent #0767d0;
          right: 15px;
          top: -11px;
        }

        &::after {
          border-color: transparent transparent #fff;
          right: 15px;
          top: -10px;
        }

        .application-list {
          max-height: calc(100vh - 120px);
        }
      }
    }
  }

  .notification-dropdown {
    .notification {
      @apply relative;

      margin-right: 15px;
      margin-top: 10px;

      &_alert {
        @apply bg-red-500 absolute rounded-full text-white text-center;

        bottom: 2px;
        font-size: 12px;
        height: 20px;
        left: -16px;
        width: 20px;
      }
    }

    &_list {
      border-radius: 5px;
      height: 100%;
      max-height: calc(100vh - 150px);
      overflow: auto;
      word-wrap: break-word;

      &_item {
        @apply flex-col items-start;

        .notification-info {
          @apply mb-1 text-left font-bold pb-2;

          width: 100%;
          word-wrap: break-word;
        }

        .notification-status {
          @apply py-1 px-3 rounded-lg mr-2;

          word-wrap: break-word;

          &.success {
            @apply bg-lime-300;
          }

          &.failure {
            @apply bg-rose-300;
          }
        }
      }
    }

    .no-notification,
    .read-more {
      @apply py-1 text-center;
    }
  }
}
